import { styled } from "@mui/material";
import { IFilter } from "../../../hooks/useMultiSelectionFilter";
import { compact } from "lodash";

export const Filters = styled("div")({
    display: "flex",
    gap: 8,
    flexWrap:"wrap"
});

export const FilterComponents = ({ filters, style }: { filters: IFilter[]; style?: React.CSSProperties }) => (
    <Filters style={style}>{filters.map((filter) => filter.component)}</Filters>
);

export const FilterResetComponents = ({ filters, style }: { filters: IFilter[]; style?: React.CSSProperties }) => {
    const resetComponents = compact(filters.map((filter) => filter.resetComponent));
    return resetComponents.length > 0 ? (
        <Filters style={style}>{filters.map((filter) => filter.resetComponent)}</Filters>
    ) : null;
};
