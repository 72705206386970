import React from "react";
import { FilterContainer } from "./FilterContainer";
import { Icon } from "../../util/Icon";
import { CmsMenu } from "../Primitives";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Button } from "@mui/material";
import { t } from "../../../i18n/util";
import { FILTER_DATE_DISPLAY_FORMAT } from "../../../hooks/useDateRangeFilter";
import { StyledButton, StyledMenuItem } from "./MultiSelectionFilterButton";

interface CurrentDateProperties {
    date: dayjs.Dayjs | null;
    error?: string;
}

const FilterMenu = ({
    dateFrom,
    dateTo,
    anchorElement,
    onApply,
    onClose,
}: {
    dateFrom: dayjs.Dayjs | null;
    dateTo: dayjs.Dayjs | null;
    anchorElement: any;
    onApply: (dateFrom: dayjs.Dayjs | null, dateTo: dayjs.Dayjs | null) => void;
    onClose: () => void;
}) => {
    const [currentDateFrom, setCurrentDateFrom] = React.useState<CurrentDateProperties>({ date: dateFrom });
    const [currentDateTo, setCurrentDateTo] = React.useState<CurrentDateProperties>({ date: dateTo });

    const handleClickReset = () => {
        onApply(null, null);
        onClose();
    };

    const handleClickApply = () => {
        onApply(currentDateFrom.date, currentDateTo.date);
        onClose();
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <CmsMenu
                anchorEl={anchorElement}
                open={!!anchorElement}
                onClose={onClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <div style={{ display: "flex", gap: 16, padding: "16px 16px 0 16px" }}>
                    <DatePicker
                        format={FILTER_DATE_DISPLAY_FORMAT}
                        label={t("common.date.from")}
                        value={currentDateFrom.date}
                        onChange={(newValue) =>
                            setCurrentDateFrom((currentValue) => {
                                return { ...currentValue, date: newValue };
                            })
                        }
                        onError={(newError) =>
                            setCurrentDateFrom((currentValue) => {
                                return { ...currentValue, error: newError?.toString() };
                            })
                        }
                        sx={{ maxWidth: 200 }}
                    />
                    <DatePicker
                        format={FILTER_DATE_DISPLAY_FORMAT}
                        label={t("common.date.to")}
                        value={currentDateTo.date}
                        onChange={(newValue) =>
                            setCurrentDateTo((currentValue) => {
                                return { ...currentValue, date: newValue };
                            })
                        }
                        onError={(newError) =>
                            setCurrentDateTo((currentValue) => {
                                return { ...currentValue, error: newError?.toString() };
                            })
                        }
                        sx={{ maxWidth: 200 }}
                    />
                </div>

                <StyledMenuItem style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 16 }}>
                    <Button onClick={handleClickReset}>{t("filter.button.reset")}</Button>
                    <StyledButton
                        onClick={handleClickApply}
                        variant="contained"
                        disabled={!!currentDateFrom.error || !!currentDateTo.error}
                    >
                        {t("filter.button.apply")}
                    </StyledButton>
                </StyledMenuItem>
            </CmsMenu>
        </LocalizationProvider>
    );
};
export const DateRangeFilterButton = ({
    label,
    onApply,
    dateFrom,
    dateTo,
}: {
    label: string;
    onApply: (dateFrom: dayjs.Dayjs | null, dateTo: dayjs.Dayjs | null) => void;
    dateFrom: dayjs.Dayjs | null;
    dateTo: dayjs.Dayjs | null;
}) => {
    const [menuAnchorElement, setMenuAnchorElement] = React.useState<null | HTMLElement>(null);

    const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorElement(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setMenuAnchorElement(null);
    };

    return (
        <>
            <FilterContainer isSelected={dateFrom || dateTo} onClick={handleClickMenu}>
                <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                    <span>{label}</span>
                    <div>
                        <Icon name="chevronDown" />
                    </div>
                </div>
            </FilterContainer>
            {!!menuAnchorElement && (
                <FilterMenu
                    anchorElement={menuAnchorElement}
                    onClose={handleCloseMenu}
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                    onApply={onApply}
                />
            )}
        </>
    );
};
