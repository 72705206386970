import * as React from "react";
import { useDeepCompareCallback } from "use-deep-compare";
import { t } from "../i18n/util";
import { API } from "../network/API";
import { GetUserRequestsResponse } from "../network/APITypes";
import { generalStore } from "../stores/GeneralStore";
import { UserPaginationOptions } from "../types";

export const useUsers = (
    options?: UserPaginationOptions,
    loadCondition = true,
    preventLoadingStateDuringInitiation?: boolean,
) => {
    const [response, setResponse] = React.useState<GetUserRequestsResponse | null>(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isInitialized, setIsInitialized] = React.useState(false);

    const load = useDeepCompareCallback(async () => {
        if (!(!isInitialized && preventLoadingStateDuringInitiation)) {
            generalStore.setIsLoading(true);
        }

        setIsLoading(true);

        try {
            const res = await API.getUsers(options);
            setResponse(res);
            setIsInitialized(true);
        } catch (error) {
            generalStore.setError(t("error.loadUsers"), error);
        } finally {
            generalStore.setIsLoading(false);
            setIsLoading(false);
        }
    }, [options]);

    React.useEffect(() => {
        if (loadCondition) {
            load();
        }
    }, [load, loadCondition]);

    const resetUsers = React.useCallback(() => {
        setResponse(null);
        setIsInitialized(false);
    }, []);

    return {
        users: response,
        reloadUsers: load,
        resetUsers,
        isLoading,
        isInitialized,
    };
};
