import { useLocation } from "react-router";
import { useQuery } from "./useQuery";
import { pushRoute, withQuery } from "../components/app/router/history";
import { DateRangeFilterButton } from "../components/ui/filters/DateRangeFilterButton";
import { RemoveFilterButton } from "../components/ui/filters/RemoveFilterButton";
import dayjs from "dayjs";
import { generalStore } from "../stores/GeneralStore";
import { t } from "../i18n/util";

type DateRangeFilterProps<T> = {
    nameFrom: keyof T;
    nameTo: keyof T;
    label: string;
};
export const FILTER_DATE_DISPLAY_FORMAT = "DD.MM.YYYY";
export const FILTER_DATE_API_FORMAT = "YYYY-MM-DD";
export const useDateRangeFilter = <T extends object>({ nameFrom, nameTo, label }: DateRangeFilterProps<T>) => {
    const location = useLocation();
    const queryParams = useQuery();

    const valueFrom = (queryParams[nameFrom] as string) || undefined;
    const valueTo = (queryParams[nameTo] as string) || undefined;

    const dateFrom = valueFrom ? dayjs(valueFrom) : null;
    const dateTo = valueTo ? dayjs(valueTo) : null;

    const handleApply = (dateFrom: dayjs.Dayjs | null, dateTo: dayjs.Dayjs | null) => {
        if ((!dateFrom?.isValid() && dateFrom) || (!dateTo?.isValid() && dateTo)) {
            generalStore.setError(t("filter.date.validation.invalid"));
            return;
        }
        pushRoute(
            withQuery(location.pathname, {
                ...queryParams,
                page: 1,
                [nameFrom]: dateFrom?.format(FILTER_DATE_API_FORMAT),
                [nameTo]: dateTo?.format(FILTER_DATE_API_FORMAT),
            }),
        );
    };

    const component = <DateRangeFilterButton label={label} onApply={handleApply} dateFrom={dateFrom} dateTo={dateTo} />;

    const handleRemove = () => {
        pushRoute(
            withQuery(location.pathname, { ...queryParams, page: 1, [nameFrom]: undefined, [nameTo]: undefined }),
        );
    };

    const displayValues: string[] = [dateFrom, dateTo]
        .filter((value): value is dayjs.Dayjs => value !== null)
        .map((value) => dayjs(value).format(FILTER_DATE_DISPLAY_FORMAT));

    const resetComponent = displayValues.length > 0 && (
        <RemoveFilterButton
            key={nameFrom.toString() + nameTo.toString()}
            label={label}
            values={displayValues}
            onRemove={handleRemove}
        />
    );

    return {
        nameFrom,
        nameTo,
        valueFrom,
        valueTo,
        component,
        resetComponent,
    };
};
